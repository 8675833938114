// Pain category input show/hide when pain log connected measurement select changed.
$(document).on('turbolinks:load', function () {
  $('.js-drug-connected-measurements-select').each(processPainCategoryDrugInputVisibility);

  $('.js-drug-connected-measurements-select').on('change', processPainCategoryDrugInputVisibility);
});

function processPainCategoryDrugInputVisibility() {
  let selectedValues = $(this).find(':selected').map(function () { return `${$(this).val()}` }).toArray();

  if(selectedValues.indexOf('pain_log') >= 0) {
    $('.js-drug-pain-category-input').removeClass('d-none');
  } else {
    $('.js-drug-pain-category-input').addClass('d-none');
  }
}

// Image and Video previews.
$(document).on('turbolinks:load', function () {
  $('.js-drug-image-input').on('change', function () {
    let file = this.files[0]

    if (file) {
      $('.js-drug-image-input-preview').attr('src', URL.createObjectURL(file))
    }
  })

  $('.js-drug-video-input').on('change', function () {
    let file = this.files[0]

    if (file) {
      $('.js-drug-video-input-preview').attr('src', URL.createObjectURL(file))
    }
  })
})

// Usage instructions inputs show/hide according selected format.
$(document).on('turbolinks:load', function () {
  $('.js-drug-usage-instructions-format-radio').each(processDrugUsageInstructionsFieldsVisibility)

  $('.js-drug-usage-instructions-format-radio').on('change', processDrugUsageInstructionsFieldsVisibility)
})

function processDrugUsageInstructionsFieldsVisibility() {
  if($(this).is(':checked') && $(this).val() === 'external_url') {
    $('.js-drug-usage-instructions-external-url').removeClass('d-none')
    $('.js-drug-usage-instructions-external-url').prop('disabled', false);

    $('.js-drug-usage-instructions-file-area').addClass('d-none')
    $('.js-drug-usage-instructions-file-area input').prop('disabled', true);
  } else if($(this).is(':checked') && $(this).val() === 'file') {
    $('.js-drug-usage-instructions-external-url').addClass('d-none')
    $('.js-drug-usage-instructions-external-url').prop('disabled', true);

    $('.js-drug-usage-instructions-file-area').removeClass('d-none')
    $('.js-drug-usage-instructions-file-area input').prop('disabled', false);
  }
}
