// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery'
import 'popper.js'
import 'bootstrap'
import 'metismenu'
import 'pace'
import 'jquery-slimscroll'
import './inspinia'
import './recommended_dosage'
import './drug'
import '@fortawesome/fontawesome-free/js/all'
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap-select'
import '@nathanvda/cocoon'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

global.$ = jQuery

$(document).on('turbolinks:load', function () {
  $(".js-custom-file-input").on("change", function() {
    let fileName = $(this).val().split("\\").pop();
    $(this).siblings(".js-custom-file-label").addClass("selected").html(fileName);
  });

  $(".js-select").selectpicker();
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
