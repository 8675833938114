$(document).on('turbolinks:load', function () {
  $('.js-recommended-dosage-schedule-type-select').each(processRecommendedDosageScheduleInputsVisibility);

  $('.js-recommended-dosage-schedule-type-select').on('change', processRecommendedDosageScheduleInputsVisibility);
});

function processRecommendedDosageScheduleInputsVisibility() {
  let recommendedDosageId = $(this).data('recommended-dosage-id');
  let selectedValue = $(this).val();

  $(`.js-recommended-dosage-form[data-recommended-dosage-id="${recommendedDosageId}"] .js-schedule-input`)
    .addClass('d-none');

  switch (selectedValue) {
    case 'every_n_days':
      $(`.js-recommended-dosage-form[data-recommended-dosage-id="${recommendedDosageId}"] .js-every-n-days-input`)
        .removeClass('d-none');
      break;
    case 'weekdays':
      $(`.js-recommended-dosage-form[data-recommended-dosage-id="${recommendedDosageId}"] .js-weekdays-input`)
        .removeClass('d-none');
      break;
    default:
      break;
  }
}
